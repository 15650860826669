import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { v4 as uuidv4 } from "uuid"
import { Header } from "../../UI/Common"
import Breadcrumb from "../../UI/Breadcrumb/Breadcrumb"
import ModalTriggerButton from "../../UI/ModalTriggerButton"

const HeaderWithPopupOption = ({
  sectionHeading,
  description,
  image,
  breadcrumbData,
  config,
  bikePurchasePopup,
  partExchangePopup,
  location
}) => {
  const { padding } = config || {}

  return (
    <Header padding={padding}>
      <>
        <Container className="text-left">
          <Row>
            <Col xs={12} lg={6} key={uuidv4()} className="my-3 my-lg-0">
            <Breadcrumb className="pl-1" data={breadcrumbData} />
              <h1 className="pt-lg-5 pt-3">{sectionHeading}</h1>
                <div className="mb-5">{description}</div>
              <div className="mb-3 d-flex">
              {partExchangePopup?.buttonTitle && (
                <ModalTriggerButton
                  heading={partExchangePopup?.formHeading}
                  btnClassName="px-2"
                  variant="outline-primary"
                  buttonText={partExchangePopup?.buttonTitle}
                  type="partExchange"
                  location
                />
              )}
              {bikePurchasePopup.buttonTitle && (
                <ModalTriggerButton
                  heading={bikePurchasePopup?.formHeading}
                  btnClassName="px-3 mx-2"
                  variant="outline-primary"
                  buttonText={bikePurchasePopup?.buttonTitle}
                  type="partExchange"
                  intent="sell"
                />
              )}
              </div>
            </Col>
            <Col xs={12} lg={6} key={uuidv4()} className="my-3 my-lg-0">
              <GatsbyImage
                alt={image.altText || ""}
                image={getImage(image.localFile)}
              />
            </Col>
          </Row>
        </Container>
      </>
    </Header>
  )
}

export default HeaderWithPopupOption
