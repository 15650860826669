import React from "react"
import * as PropTypes from "prop-types"
import { Link } from "../../UI/Common"

import * as style from "./Breadcrumb.module.scss"

const Breadcrumb = ({ data, className }) => {
  return (
    <div className={`${style.container} ${className}`}>
      {data &&
        React.Children.toArray(data.map((item, index) => {
          if (index === 0) {
            return (
              <>
                {item.name === "Home" ? (
                  <span>
                    <Link link={item.path} className={style.link}>
                      {item.name}
                    </Link>
                  </span>
                ) : (
                  <>
                  <span>
                    <Link link="/" className={style.link}>
                      Home
                    </Link>
                  </span>
                  <span>
                    <span className={style.spacer}>{" >"}</span>
                      &nbsp;
                    <Link link={item.path} className={style.link}>
                      {item.name}
                    </Link>
                  </span>
                 </>
                )}
              </>
            )
          }

          return (
            <span>
              <span className={style.spacer}>{" >"}</span>
              &nbsp;
              <Link link={item.path} className={style.link}>
                {item.name}
              </Link>
            </span>
          )
        }))}
      {!data && (
        <span>
          <Link link="/" className={style.link}>
            Home
          </Link>
        </span>
      )}
    </div>
  )
}

Breadcrumb.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      path: PropTypes.string,
    })
  ),
  className: PropTypes.string,
}

Breadcrumb.defaultProps = {
  className: "",
}

export default Breadcrumb
